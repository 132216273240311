@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;1,100&display=swap');

.Header {
  background-image: url("../media/bg1.png");
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(238, 238, 238);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.514);
}
#headerMain{
  position: absolute; 
  top:20vh;
}
#headerName{
  display: flex;
  letter-spacing: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  font-family: 'Pacifico', cursive;
}
#headerSub{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(18px + 2vmin);
  font-family: 'Righteous', cursive;
}
#nav{
  
  position: absolute;
  bottom:10px;
  font-family: 'Righteous', cursive;
}
#navLinks{
  font-size: calc(16px + 2vmin); 
}
#navLinks a{
  color:  rgb(238, 238, 238);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  text-decoration: none;
}
#navLinks a:hover{
  color: rgba(255, 255, 255, 0.726);
}

.Footer{
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color:rgb(212, 212, 212);
}
.Footer p{
  margin-top: -20px;
  font-family: 'Righteous', cursive;
}

h1{
  font-family: 'Righteous', cursive;
}

#aboutMain{
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  margin-bottom: 80px;
  text-align: center;
  padding-left:10%;
  padding-right:10%;
}
#aboutContact{
  font-size: 18px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
}
.About{
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.514);
  /* background-image: url("../media/beanstalk-dark.png"); */
  background-color: #c42c57;
  padding-top: 80px;
  padding-bottom: 80px;
  margin: auto;
  margin-top:100px;
  margin-bottom: 40px;
  position: relative;
  width:60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:rgb(212, 212, 212);
  border-radius: 20px;

}
.About a{
  color: rgb(212, 212, 212);
  text-decoration: none;
  font-weight: bold;
}
@media only screen and (min-width:769px){
  #myImg{
    position: absolute;
    top:-60px;
    left:-30px;
    width:200px;
    border: solid black 7px;
    box-shadow: inset 0px 0px 0px 10px black;
    border-radius: 50%;
    filter: grayscale(100%);
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  }
}

#projects{
  width: 60%;
  margin: auto;
  padding-top:60px;
}

.ProjectFullW{
  width: 90%;
  /* display: inline-block; */
  margin-bottom: 60px;
  margin-top: 20px;
  margin-left: auto;
}
.ProjectFullW img, .ProjectFullWShowTablet img, .ProjectFullWShowPhone img{
  width:90%;
  border-radius: 1%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ProjectHalf{
  width: 40%;
  margin-bottom: 40px;
  margin-top: 20px;
  display: inline-block;
  padding:5%;
}
.ProjectHalf img, .ProjectHalfShowTablet img{
  width: 100%;
  border-radius: 1%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ProjectThird{
  width: 28%;
  display: inline-block;
  margin-bottom: 80px;
  margin-top: 20px;
  padding:2.5%;
}
.ProjectThird img{
  width: 100%;
  border-radius: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ProjectThird img, .ProjectHalf img, .ProjectFullW img, .ProjectHalfShowTablet img, .ProjectFullWShowTablet img, .ProjectFullWShowPhone img {
  filter: grayscale(30%);
}
.ProjectThird img:hover, .ProjectHalf img:hover, .ProjectFullW img:hover, .ProjectHalfShowTablet img:hover, .ProjectFullWShowTablet img:hover, .ProjectFullWShowPhone img:hover {
  filter: grayscale(0%);
  cursor: pointer;
}
.modal-content{
  filter: grayscale(0%) !important; 
}
.modal::-webkit-scrollbar{
  display: none;
}

.ProjectFullWShowTablet{
  width: 90%;
  display: none;
  margin-bottom: 60px;
  margin-top: 20px;
  margin-left: auto;
}
.ProjectHalfShowTablet{
  width: 40%;
  margin-bottom: 40px;
  margin-top: 20px;
  padding:5%;
  display: none;
}
.ProjectFullWShowPhone{
  width: 90%;
  display: none;
  margin-bottom: 60px;
  margin-top: 20px;
  margin-left: auto;
}

/* Modal */
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto !important;
  display: block !important;
  width: auto !important;
  max-width:80% !important;
  max-height: 65% !important;
}

/* Caption of Modal Image */
.caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  font-size:18px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
  padding-bottom: 100px;
  font-family: 'Raleway', sans-serif;
}
.caption a {
  text-decoration: none;
  color: white;
  text-emphasis: bold;
}

/* Add Animation */
.modal-content, .caption {  
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {transform: scale(0.1)} 
  to {transform: scale(1)}
}

#modalTitle{
  margin: auto;
  display: block;
  width: 80%;
  font-size:20px;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  font-family: 'Righteous', cursive;
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}

@media only screen and (max-width: 1366px){
  .About{
  padding-left:10%;
  padding-right:10%;
  }
}

@media only screen and (max-width:1024px){
  #projects{
    width:80%;
  }
}

@media only screen and (max-width:768px){
  #myImg{
    position: absolute;
    top:-80px;
    width:150px;
    border: solid black 7px;
    box-shadow: inset 0px 0px 0px 10px black;
    border-radius: 50%;
    filter: grayscale(100%);
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  }
  .ProjectThird{
    display: none;
  }
  .ProjectHalfShowTablet{
    display: inline-block;
  }
  .ProjectFullWShowTablet{
    display: block;
  }
}

@media only screen and (max-width:425px){
  #projects{
    width:90%;
  }
  .ProjectHalf{
    display: none;
  }
  .ProjectHalfShowTablet{
    display: none;
  }
  .ProjectFullWShowPhone{
    display: block;
  }
}